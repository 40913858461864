import dynamic from "next/dynamic";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { locationAction } from "../stores/Slices/LocationSlice";
import { BreadcrumbJsonLd } from "next-seo";
import { homeJson } from "../data/jsonLD/search";

const NextHelmet = dynamic(
  () => import("../components/NextElements/NextHelmet"),
  {
    ssr: true,
  }
);
const Banner = dynamic(() => import("../components/Sections/Banner"), {
  ssr: true,
});
const Why = dynamic(() => import("../components/Sections/Why"), {
  ssr: true,
});
const How = dynamic(() => import("../components/Sections/How"), {
  ssr: true,
});
const CareHomes = dynamic(() => import("../components/Sections/CareHomes"), {
  ssr: true,
});
const Claim = dynamic(() => import("../components/Sections/Claim"), {
  ssr: true,
});
const RegionList = dynamic(() => import("../components/Sections/RegionList"), {
  ssr: true,
});

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationAction.getFilterData());
  }, []);

  return (
    <>
      <NextHelmet title="Home" />
      <BreadcrumbJsonLd itemListElements={homeJson} />
      <Banner />
      <CareHomes />
      <Why />
      <How />
      <RegionList />
      <Claim />
    </>
  );
};

export default Home;
