export const homeJson = [
  {
    position: 1,
    name: "Carefindr",
    item: `${process.env.NEXT_PUBLIC_SITE_LINK}`,
  },
]

export const searchJson = [
  {
    position: 1,
    name: "Carefindr",
    item: `${process.env.NEXT_PUBLIC_SITE_LINK}`,
  },
  {
    position: 2,
    name: "Carefindr Search Care Homes",
    item: `${process.env.NEXT_PUBLIC_SITE_LINK}/search`,
  },
];